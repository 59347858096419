<template>
  <div class="main-container">
    <div class="background-area">
      <img src="../../assets/banner-2.jpg" alt="banner-2">
      <div class="caption">
        <h1>全新ERP</h1>
      </div>
    </div>

    <div class="container-area">
      <img src="../../assets/function-intro/erp3/erp3.png" alt="erp3">
    </div>

    <Footer/>
  </div>
</template>

<script>
import Footer from "../../components/footer/footer"
export default {
  name: "Erp3",
  components: {Footer},
  comments: {
    Footer
  }
}
</script>

<style scoped>
  .background-area {
    position: relative;
  }
  .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 10px;
    color: white;
  }
</style>
