<template>
  <div>
    <img src="../../assets/2-4.png" alt=""/>
    <!--  添加备案信息 -->
    <div class="record-keeping">
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2024312059号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer"
}
</script>

<style scoped>

</style>
